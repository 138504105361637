import * as React from "react"
import { useEffect } from "react";

import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import ImageHero from "../images/hero-pills.png"
import ImageLineSmall from "../images/line-small.svg"
import ImageLineLong from "../images/line-long.svg"
import ImageBgTodaysStandards from "../images/global-bg-references.png"
import ImagePillsBottle from "../images/standard-care-pills-spilling-from-bottle.png"
import ImagePelvis from "../images/standard-care-zoomed-in-inflammation.png"
import ImageNoSingularHorizontal from "../images/home-no-singular-horizontal-line.png"
import ImageDesktopStandardCare from "../images/icons-desktop-standard-care.png"
import ImageMobileStandardCare from "../images/icons-mobile-standard-care.png"
import ImageIconCalloutArrow from "../images/icon-callout-brown-arrow.svg"

//import ReactPlayerLoader from '@brightcove/react-player-loader';

const StandardOfCare = () => {

  //const vp = `<video-js data-account="3845398857001" data-player="default" data-embed="default" controls="" data-video-id="6318816730112" data-playlist-id="" data-application-id="" width="300" height="150" class="vjs-fluid"></video-js>`;

  // const videoSuccess =(success)=>{
  //   let el = success.ref.el_;
  //   el.setAttribute("width", 300);
  //   el.setAttribute("height", 150);
  //   el.classList.add('vjs-fluid');
  // }
  useEffect(() => {
    // Load Vimeo player script
    const script = document.createElement("script");
    script.src = "https://player.vimeo.com/api/player.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Layout pageid="standard-of-care">
      <Seo title="Standard of Care | PMR and IL-6 HCP Website" description="Read about standard of care for PMR." ogDesc="Read about the standard of care for PMR." lang="en-US" meta=""/>
      <div className="content-container">
        <div id="hero-image-container" className="mb-8 md_mb-8">
          <h1>STANDARD <br className="show-for-large"/>OF CARE</h1>
          <img src={ImageHero} alt="pills" id="hero-standard-of-care" />
        </div>

        <div id="standard-todays-standards" className="mb-17 md_mb-12">
          <div className="content-container">
            <img src={ImageBgTodaysStandards} alt="" id="standard-image-bg-todays-standards" />
            <h2 className="section-headline">Standard of care for PMR begins with glucocorticoids (GCs)<sup>1-4</sup></h2>
            <p className="show-for-small-only"><img src={ImageLineSmall} alt="" className="mb-2 mt-2 md_mb-2 md_mt-2"/></p>
            <p className="show-for-medium"><img src={ImageLineLong} alt="" className="mb-2 mt-2 md_mb-2 md_mt-2"/></p>
            <h2 className="section-subhead mb-2 md_mb-2">Strong recommendations for treating PMR from the American College of Rheumatology and the European Alliance of Associations for Rheumatology<sup>5</sup>:</h2>
            <ul>
              <li>Using GCs instead of NSAIDs, with the exception of possible short-term NSAIDs and/or analgesics in patients with pain related to other conditions<sup>5</sup></li>
            	<li>Using a minimum effective individualized duration of GC therapy<sup>5</sup></li>
            	<li>Against the use of anti-TNF&alpha; agents<sup>5</sup></li>
            </ul>
            <div className="mb-4 md_mb-4 mt-4 md_mt-4 text-center"><img src={ImageNoSingularHorizontal} alt="" /></div>
            <h2 className="section-subhead mb-0 md_mb-2">Steroids are one of the most widely prescribed classes of drug in the world<sup>6</sup></h2>
            <p className="show-for-small-only"><img src={ImageLineSmall} alt="" className="mb-2 mt-2 md_mb-2 md_mt-2"/></p>
            <p className="show-for-medium"><img src={ImageLineLong} alt="" className="mb-2 mt-0 md_mb-2 md_mt-0"/></p>
            <p>Low-to-moderate dosages of glucocorticoids (GCs) have quickly corrected disease manifestations in many patients since the 1960s, which is why they have been coined as the cornerstone of treatment for PMR patients.<sup>3</sup> Without a specific test for PMR, clinicians often recognize a prompt response to GCs as supportive of a PMR diagnosis.<sup>7</sup> Steroids typically lead to a dramatic improvement in PMR symptoms within the first few days. Once PMR symptoms are stable, the tapering process is started. However, not all patients are able to taper off steroids. Stopping steroids or tapering can be challenging due to potential of relapse.<sup>8,9</sup></p>
          </div>
          <div className="callout-orange align-right mt-4 md_mt-4 mb-4 md_mb-4" style={{ width: '85%' }}>
            <p>GCs do not always correct the underlying mechanism of PMR<sup>10</sup></p>
          </div>
          <img src={ImagePillsBottle} alt="pills spilling from bottle" id="standard-image-pills-bottle" />
        </div>

        <div id="standard-strong-recommendations" className="mb-4 md_mb-4 lg_mb-2">
          <div className="content-container mb-4 md_mb-4">
            <h2 className="section-subhead mb-3 md_mb-3">Although there is a standard of care, challenges still remain<sup>1-4</sup></h2>
            <p className="bg-color-gray-light text-center show-for-medium mb-4 md_mb-4"><img src={ImageDesktopStandardCare} alt="time on therapy icon, variable efficacy icon, increased risk of GC-related toxicities icon" style={{ width: '70%', padding: '4% 0 3% 0' }} /></p>
            <p className="bg-color-gray-light text-center show-for-small-only mb-4 md_mb-4"><img src={ImageMobileStandardCare} alt="time on therapy icon, variable efficacy icon, increased risk of GC-related toxicities icon" /></p>
            <p className="mb-1 md_mb-1">GC treatment considerations:</p>
            <ul>
              <li>Up to 43% of patients taking GCs relapse within 1 year<sup>3</sup></li>
              <li>GCs lead to risk of medication-related complications such as cardiovascular disease, osteoporosis, and diabetes<sup>11,12</sup></li>
              <li>Patients with comorbidities that are commonly seen in older adults—osteoporosis, uncontrolled hyperglycemia, diabetes mellitus, glaucoma, joint infection, and uncontrolled hypertension. Caution should be used when considering GC treatment for patients with these comorbidities<sup>6</sup></li>
              <li>Tapering off steroids is not a one-size-fits-all process. Tapering or discontinuing steroids can be challenging due to complications such as relapse<sup>5,13</sup></li>
              <li>Some patients may not receive adequate management with GCs and thus do not have a standard of care<sup>1</sup></li>
            </ul>
          </div>
        </div>

        <div id="il-6-understanding-pmr-container" className="mb-6 md_mb-6 lg_mb-2">
          <div className="content-container">
            <h2 className="section-headline">PMR Management: Challenges and Opportunities </h2>
            <p><img src={ImageLineLong} alt="" className="mb-2 mt-2 md_mb-2 md_mt-2"/></p>
            <p className="section-subhead mb-4 md_mb-4">Watch Dr Stone Discuss the Challenges and Opportunities of PMR Management</p>

            <div id="il-6-video-container" className="mb-4 md_mb-4">
                <div style={{ paddingTop: "56.21%", position: "relative", margin: 0, border: "1px solid #f4f4f4", overflow: 'hidden',  width: 'calc(100% - 1px)' }}>
                  <iframe
                    src="https://player.vimeo.com/video/949575142?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                    style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                    title="The Pathophysiology of PMR and the Role of Elevated IL-6 in PMR"
                  ></iframe>
              </div>
            </div>

            <p className="mb-1 md_mb-1">Listen to an expert’s perspective on:</p>
            <ul className="mb-6 md_mb-6">
              <li>Current options available to manage PMR symptoms</li>
              <li>Appropriate patient types</li>
              <li>Future state of PMR management</li>
            </ul>
          </div>
        </div>

        <div id="standard-pelvis-image-container">
          <img src={ImagePelvis} alt="zoomed in inflammation" id="standard-image-pelvis" />
        </div>

        <Link to="/impact-on-patients/" className="gen-brown-callout">
          <div className="callout-brown-generic right mb-2 md_mb-2" id="callout-standard-care-pelvis">
            <div className="row">
              <div className="columns small-12 medium-9 text-center">The impact of PMR <span className="nowrap-desktop">on patients</span></div>
              <div className="columns small-12 medium-3 text-center nowrap align-self-middle"><img src={ImageIconCalloutArrow} alt="" /></div>
            </div>
          </div>
        </Link>

        <div id="references" className="standard-care">
          <div id="references-content">
            <p>
              <strong>References: 1.</strong> Buttgereit F, Dejaco C, Matteson EL, Dasgupta B. Polymyalgia rheumatica and giant cell arteritis: a systematic review. <em>JAMA.</em> 2016;315(22):2442-2458.&nbsp;
              <strong>2.</strong> Acharya S, Musa R. Polymyalgia Rheumatica. <em>NCBI Bookshelf.</em> StatPearls [Internet]. Treasure Island (FL): StatPearls Publishing; 2022.&nbsp;
              <strong>3.</strong> Floris A, Piga M, Chessa E, et al. Long-term glucocorticoid treatment and high relapse rate remain unresolved issues in the real life management of polymyalgia rheumatica: a systematic literature review and meta-analysis. <em>Clinical Rheum.</em> 2022;41:19-31.&nbsp;
              <strong>4.</strong> Mazzantini M, Torre C, Miccoli M, et al. Adverse events during longterm low-dose glucocorticoid treatment of polymyalgia rheumatica: a retrospective study. <em>Journal Rheumatol.</em> 2012;39(3):552-557.&nbsp;
              <strong>5.</strong> Dejaco C, Singh YP, Perel P, et al. 2015 recommendations for the management of polymyalgia rheumatica: a European League Against Rheumatism/American College of Rheumatology collaborative initiative. <em>Ann Rheum Dis.</em> 2015;74:1799-1807.&nbsp;
              <strong>6.</strong> Hodgens A, Sharman T. Corticosteroids. 2022. In: StatPearls [Internet]. Treasure Island (FL): StatPearls Publishing.&nbsp;
              <strong>7.</strong> Kermani TA, Warrington KJ. Advances and challenges in the diagnosis and treatment of polymyalgia rheumatica. <em>Ther Adv Musculoskel Dis.</em> 2014;6(1):8-19.&nbsp;
              <strong>8.</strong> Bartoloni E, Pucci G, Alunno A, et al. Polymyalgia rheumatica. <em>The Heart in Rheumatic, Autoimmune and Inﬂammatory Diseases.</em> http://dx.doi.org/10.1016/B978-0-12-803267-1.00009-0.&nbsp;
              <strong>9.</strong> Hagihara K, Kawase I, Tanaka T, Kishimoto T. Tocilizumab ameliorates clinical symptoms in polymyalgia rheumatica. <em>J Rheumatol.</em> 2010;37(5):1075-1076.&nbsp;
              <strong>10.</strong> Roche NE, Fulbright JW, Wagner AD, et al. Correlation of interleukin-6 production and disease activity in polymyalgia rheumatica and giant cell arteritis. <em>Arthritis & Rheumatism.</em> 1993;36(9):1286-1294.&nbsp;
              <strong>11.</strong> Feve B, Scheen AJ. When therapeutic drugs lead to diabetes. <em>Diabetologia.</em> 2022;65(5):751-762.&nbsp;
              <strong>12.</strong> Pujades-Rodriguez M, Morgan AW, Cubbon RM, Wu J. Dose-dependent oral glucocorticoid cardiovascular risks in people with immune-mediated inflammatory diseases: a population-based cohort study. <em>PLoS Med.</em> 2020,17(12):e1003432.&nbsp;
              <strong>13.</strong> Mahmood SB, Nelson E, Padniewski J, Nasr R. Polymyalgia rheumatica: an updated review. <em>Cleve Clin J Med.</em> 2020;87(9):549-556&nbsp;
            </p>
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default StandardOfCare
